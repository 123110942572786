import moment from 'moment';
import { TableNames } from '../../../namesConstants/names';

const bodyTable = (rowData) => {
	let textColor = '';
	switch (rowData.status) {
		case 1:
			textColor = 'text-secondary-PCS001';
			break;
		case 2:
			textColor = 'text-[#FF8585]';
			break;
		default:
			textColor = 'text-[#65CD81]';
	}
	return (
		<>
			<label className={textColor} for=''>
				{rowData.statusText}
			</label>
		</>
	);
};

const bodyDate = (rowData) => {
	const date = moment.utc(rowData.incidenceDate);
	date.startOf('day');
	const formattedDate = date.format('DD/MM/YYYY');
	return formattedDate;
};

const bodyError = (rowData) => {
	return (
		<>
			<div title={rowData.errorDetail} className=''>
				<p>{rowData.errorDetail}</p>
			</div>
		</>
	);
};

export const tableColumnsData = [
	{
		field: 'code',
		label: TableNames.TableCode,
		heigh: '100px',
		filter: false,
		showFilterMenu: false,
		showFilterOperator: false,
		showFilterMatchModes: false,
		sortable: true,
		dataType: 'text',
	},
	{
		field: 'name',
		label: TableNames.TableName,
		heigh: '300px',
		filter: false,
		showFilterMenu: false,
		showFilterOperator: false,
		showFilterMatchModes: false,
		sortable: true,
		dataType: 'text',
	},
	{
		field: 'incidenceDate',
		label: TableNames.TableDateIncident,
		heigh: '200px',
		filter: false,
		showFilterMenu: false,
		showFilterOperator: false,
		showFilterMatchModes: false,
		sortable: true,
		dataType: 'date',
		format: '{0:d}',
		body: bodyDate,
	},
	{
		field: 'workShift',
		label: TableNames.TableShift,
		heigh: '150px',
		filter: false,
		showFilterMenu: false,
		showFilterOperator: false,
		showFilterMatchModes: false,
		sortable: true,
		dataType: 'text',
	},

	{
		field: 'typeIncidence',
		label: TableNames.TableIncidentType,
		heigh: '150px',
		filter: false,
		showFilterMenu: false,
		showFilterOperator: false,
		showFilterMatchModes: false,
		sortable: true,
		dataType: 'text',
	},
	{
		field: 'incidenceTime',
		label: TableNames.TableDuration,
		heigh: '100px',
		filter: false,
		showFilterMenu: false,
		showFilterOperator: false,
		showFilterMatchModes: false,
		sortable: true,
		dataType: 'text',
	},
	{
		field: 'statusText',
		label: TableNames.TableState,
		heigh: '100px',
		filter: true,
		showFilterMenu: true,
		showFilterOperator: false,
		filterPlaceHolder: 'Buscar',
		showFilterMatchModes: false,
		dataType: 'text',
		sortable: true,
		body: bodyTable,
	},
	{
		field: 'errorDetail',
		label: TableNames.TableErrorName,
		heigh: '300px',
		body: bodyError,
	},
];
